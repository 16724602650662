/* :root {
    --primary_color: #ffffff;
    --breakpoint: 768px;
    --delay: all .2s ease-in-out;
}

 */

nav {
    /* background-color: white;
    color: white;
    transition: width 0.3s ease;
    overflow-x: unset;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 100%;
    padding-top: .5rem;
    padding-bottom: .5rem; */
        padding-bottom: .5rem;
    padding-top: .5rem;
    width: 100%;
    background-color: #fff;
}

nav .navbar_logo img {
    height: 60px;
    width: 80px;
}

nav .mobile_nav {
    display: flex;
    justify-content: space-between;
    height: 60px;
    padding: 10px;

    button {
        display: flex;
        margin: 10px 0px 0px 0px;
    }
}

nav ul {
    display: flex;
    flex-direction: column;
    gap: .75rem;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    top: -65%;
  border: 1px solid #d3c3c3;
  background-color: #ffffff;
    transition: all .75s ease-in-out;
    z-index: 999;
}

nav ul li {
    list-style-type: none !important;
    text-decoration: none !important;
    /* margin: auto; */
    font-family: sans-serif;
    padding: 0;
    max-width: var(--breakpoint);
}

.mblshow {
    top: 75px;
}

nav ul li a,
NavLink {
    color: var(--primary_color);
    border-bottom: 1px solid transparent;
    transition: all .5s ease-in;
    padding-bottom: .15rem;
    display: inline;
    padding: 2%;
}

nav ul li a:hover {
    /* border-color: var(--primary_color); */
}

nav {
    button[type="button"] {
        padding: .5rem;
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
    justify-content: end;
    }
}


.close {
    opacity: 0;
    transition: var(--delay);
    transform: rotateY(360deg);
    position: absolute;
    right: 0.5;
}

.menu {
    opacity: 1;
    transition: var(--delay);
    /* position: absolute; */
    margin-top: -1.25rem;

}

/* .topnav {
    margin-top: 10px;
} */