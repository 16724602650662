body{
  font-family: ui-sans-serif;
}
.hello{
  text-align: center;
  font-size: 22px;
  color: blue;
}

.main {
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ffffff;
  color: #12344d;
  display: flex;
}


.label{
  padding-bottom: 10px;
font-size: 16px;
font-weight: 600;
}
.labeldiv{
  text-align: start;
  padding-bottom: 5%;
}
.forgot{
  font-size: 18px;
  padding: 2%;
  font-family: ui-sans-serif;
}
.forgot a{
  text-decoration: auto;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.subbutton {
  display: inline-block !important;
  padding: 10px 20px !important;
  font-size: 16px !important;
  text-align: center !important;
  text-decoration: none !important;
  border: none !important;
  color: #ffffff !important;
  background: #ab987c !important ;
  /* background: linear-gradient(146deg, #281734, #2d1834, #464a77, #868bbd) !important; */
  border-radius: 6px !important;
  transition: background-color 0.3s, color 0.3s !important;
}
.submitbutton {
  display: inline-block !important;
  font-size: 14px !important ;
  text-align: center !important;
  text-decoration: none !important;
  border: 1px solid #000000 !important;
  color: #fff !important;
  background: linear-gradient(146deg, #281734, #2d1834, #464a77, #868bbd) !important; 
  border-radius: 5px !important;
  transition: background-color 0.3s, color 0.3s !important;
}


.subbutton:active {
  background-color: #3e8e41 !important;
}
.loginform{
  padding: 0% 10%;
}
.centercol{
  display: flex;
  align-items: center;
  justify-content: center;
}
.container{
  max-width: 1260px !important;
}

@media(max-width:767px) {
  .form-login {
    width: auto !important;
  }
.main {
  justify-content: center;
  align-items: center;
  background-color: #f5f7f9;
  color: #12344d;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.bg {
  background: #e6f0f7 !important;
  border: none !important;
  border-radius: 8px !important;
}
.labeldiv {
  text-align: start;
  padding-bottom: 10% !important;
}

}
.newbtn{
margin: 10% !important;
}
          
.exceldwnl{        
text-align: end;
font-size: 30px;
}
.remarkcol{
display: flex;
align-items: center;
}

.all2ddwnlbutton {
display: inline-block !important;
font-size: 14px !important ;
padding: 5px;
text-align: center !important;
text-decoration: none !important;
border: 1px solid #000000 !important;
color: #fff !important;
background: linear-gradient(146deg, #281734, #2d1834, #464a77, #868bbd) !important; 
border-radius: 5px !important;
transition: background-color 0.3s, color 0.3s !important;
}


.all2ddwnlbutton:active {
background-color: #3e8e41 !important;
}
.bg{
  background: #e6f0f7 !important;
    border: none !important;
    border-radius: 8px !important;
}
.form-login{
  background: #e6f0f7 !important;
  width: 460px;
  height: 45px;
  padding: 14px;
  border-radius: 8px;
  border: none;
  color: black;
  display: flex;
  justify-content: space-between;
}
.form-login.form-control {
  border: none !important;
  background: #e6f0f7 !important;
  outline: none !important;
}
.form-login input {
  /* border: none !important; */
  outline: none !important;
  /* font-size: 18px; */
  /* padding-bottom: 4px; */
  /* width: 100%; */
  /* height: 22px */
}
.form-control:focus{
  box-shadow: none !important;
}


.form-login input {
  color: #000000 !important;
}

input[type=password], input[type=text] {
    font-size: 15px;
    /* padding: .5rem; */
    color: #000000;
}