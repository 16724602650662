body{
  font-family: sans-serif !important;
  /* font-family: "Zen Kaku Gothic Antique" !important; */
}
h3{
  font-family: sans-serif !important;
  font-size: 32px !important; 
  line-height: 48px !important; 
  font-weight: 700 !important; 
  color: #171A1FFF; 
}
h4{
  font-size: 24px !important;
  font-weight: 700 !important;
}

.sideLogo{
  width: 100px !important;
}
@media(max-width:767px) {

  .sideLogo{
    width: 50px !important;
  }
}

.layout {
  display: flex;
  height: 100vh;
}
.main-content {
  flex: 1 1;
  overflow-y: auto;
  padding: 1rem;
  background: #f2f2f2;
}
.dropdown-item {
  color: #000000 !important;
}
/* neutral-900 */
