.element-container {
  padding: 2%;
  margin-bottom: 2%;
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .1);
  border: 1px solid #e6e6e6;
  border-image: initial;
  border-radius: 11px;
  background: #fff;
}
a {
  color: rgb(206 206 206) ;
  text-decoration: underline;
}
.category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
.category-title {
  text-wrap: nowrap;
  text-transform: capitalize;
  font-size: 22px;
  font-weight: 500;
  color: #000000;
  gap: 15px;
  align-items: center;
  display: flex;
}
.create-new-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #adbbab;
  color: white;
  padding: 5px 15px;
  border: none;
  border-radius: 6px;
   font-family: sans-serif !important;
  font-size: 16px;
  cursor: pointer;
}
.search-bar {
  display: flex;
  align-items: center;
  background-color: #dee9f6;
  border-radius: 12px;
  font-family: sans-serif !important;
  padding: 9px;
}
.search-bar input {
  border: none;
  background: none;
  outline: none;
  width: 100%;
  font-size: 14px;
}

.Vensearch-bar {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 9px;
}
.Vensearch-bar input {
  border: none;
  background: none;
  outline: none;
  width: 100%;
  font-size: 14px;
}
.flexend{
  display: flex;
  justify-content: end;
}
.flexstart{
  display: flex;
  justify-content: start;

}
.flexcenter{
  display: flex;
  justify-content: center;

}


.cash-in-card {
  background: #EFFCFAFF;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.cash-out-card{
  background: #FDF1F5FF;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cash-in-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 5%;
  margin-right: 5%;
}

.cash-in-header h2 {
  margin: 0;
  color: #d85475;
  font-size: 19px;
}

.cash-amount {
  font-size: 30px;
  font-weight: bold;
  color: #333;
  margin-left: 5%;
  margin-right: 5%;
}

.cart-icon {
  background: #006820;
  color: white;
  padding: 17px;
  border-radius: 8px;
  font-size: 18px;
  width: 55px;
  height: 55px;
  text-align: center;
}

.chart-container {
  /* margin-top: 20px; */
}

.recharts-bar-rectangle {
  stroke: none;
}

.recharts-tooltip-wrapper {
  font-size: 14px;
  color: #333;
}

.cashouticon{
  background-color: #900a0a;
  color: white;
  padding: 10px;
  border-radius: 8px;
  font-size: 18px;
  width: 54px;
  height: 54px;
  text-align: center;
  font-weight: bolder;
  font-size: 22px;
}

/* //table pagination // */

.table-container {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow-x: scroll !important;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th, .custom-table td {
  text-wrap: nowrap;
  padding: 9px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
  text-align: center !important;
}

.custom-table th {
  background-color: #f5f5f5;
}

.custom-table tr:nth-child(even) {
  /* background-color: #f9f9f9; */
}

.location-badge {
  display: inline-block;
  padding-bottom: 5px;
  padding-top: 2px;
  padding-left: 10px;
  padding-right: 10px;
  /* padding: 4px 8px; */
  border-radius: 20px;
  background-color: #ab987c;
  color: #000000;
  text-align: center;
  font-size: 15px;
}
.location-badge1{
  display: inline-block;
  padding-bottom: 5px;
  padding-top: 2px;
  padding-left: 10px;
  padding-right: 10px;
  /* padding: 4px 8px; */
  border-radius: 20px;
  background-color: #dbe9f8;
  color: #000000;
  text-align: center;
  font-size: 15px;
}


.action-button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}



.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
}

.pagination-info {
  font-size: 14px;
  color: #666;
}

.pagination {
  display: flex !important;
  list-style: none !important;
  padding: 0;
  margin: 0;
  text-align: center !important;
  align-items: baseline !important;
}

.page-item, .prev-next {
  margin: 0 5px;
  cursor: pointer;
  font-size: 16px;
  color: #bdbdbd;
  /* border: 1px solid #b9c7b7; */
  padding: 1px 12px;
  border-radius: 5px;
  height: 32px;
  transition: background-color 0.3s, color 0.3s;
}

.page-item:hover, .prev-next:hover {
  /* background-color: #007bff; */
  
  color: white;
}

.active-page {
  font-weight: bold;
  background-color: #7587f7;
  height: 36px;
  color: white;
  padding: 3px 12px;
  height: 32px;
  border-radius: 5px;
}

.prev-next {
  font-size: 1.2em;
  border: none;
  /* background-color: #ceeffd; */
  height: 36px; 
}
a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: none !important;
}
/* Add this CSS to your stylesheet to override the Bootstrap dropdown arrow */
.dropdown-toggle::after {
  content: '\0022EE' !important;  
  font-size: 20px !important;    
  vertical-align: 0; 
  border: none;   
  border-top: 0px !important;
    border-right: 0px !important;
    color: #000000 !important;
    font-size: 24px !important;    
}
.exportbtn{
  display: flex !important;
  align-items: center !important;
  gap: 7px !important;
  font-size: 16px !important;
  text-align: center !important;
  text-decoration: none !important;
  border: none !important;
  color: #9393e1 !important;
  background: #dee9f6 !important;
  border-radius: 10px !important;
}

.form-select {
  border: none !important;
  --bs-form-select-bg-img: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e);
  display: block;
  width: 100%;
  padding: .375rem 2.25rem .375rem .75rem !important;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: #f1f8fe !important;
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid #c5d6e4;
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.bgborder{
  border: none !important;
  background-color: #f1f8fe !important;
}
.modalsubbutton{
  background-color: #006820 !important;
  color: white;
  padding: 7px 19px;
  border: none !important;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
}
.cashsavebtn{
  background-color: #006820 !important;
  color: white;
  padding: 7px 19px;
  border: none !important;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
}
.cashoutsavebtn{
  background-color: #900a0a !important;
  color: white;
  padding: 7px 19px;
  border: none !important;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
}

.vendor-container{
  padding: 1%;
  margin-bottom: 2%;
  border-radius: 10px;
  /* box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .1); */
  /* border: 1px solid #e6e6e6; */
  border-image: initial;
  border-radius: 11px;
}
.boldtd{
  font-size: 14px !important;
  line-height: 22px !important;
  font-weight: 700 !important;
}
.cashin{
  font-size: 14px !important;
  line-height: 22px !important;
  font-weight: 700 !important;
  color: #006820 !important;
}
.cashout{
  font-size: 14px !important;
  line-height: 22px !important;
  font-weight: 700 !important;
  color: #900a0a !important;
}
.location-badge {
  display: inline-block;
  padding: 4px 35px;
  border-radius: 25px;
  font-size: 0.9em;
  color: #fff;
}
.location-badge1{
  display: inline-block;
  padding: 4px 35px;
  border-radius: 25px;
  font-size: 0.9em;
  color: #000000;
}
:where(.css-dev-only-do-not-override-1hpnbz2).ant-picker-outlined {
  background: #dbe9f8 !important;
  border-width: 0px !important;
  border-style: solid;
  border-radius: 10px;
  border-color: #d9d9d9;
  color: #000000;
  padding: 8px;
}
.dflexAcenter{
  padding-bottom: .5rem !important;
  display: flex !important;
  align-items: center !important;
}
.cashinbtn{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #006820;
  color: white;
  padding: 8px 15px;
  border: none;
  border-radius: 6px;
   font-family: sans-serif !important;
  font-size: 16px;
  cursor: pointer;
}

.cashoutbtn{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #900a0a;
  color: white;
  padding: 8px 15px;
  border: none;
  border-radius: 6px;
   font-family: sans-serif !important;
  font-size: 16px;
  cursor: pointer;
}
.recharts-cartesian-axis-line{
  display: none !important;
}
.boldtotal{
  font-size: 16px !important;
  line-height: 22px !important;
  font-weight: 700 !important;
  color: #006820 !important;
}
.boldtotalout{
  font-size: 16px !important;
  line-height: 22px !important;
  font-weight: 700 !important;
  color: #900a0a !important;
}

/* //card content// */

.clientcard {
  background-color: #ffffff; /* Light background color */
  border-radius: 10px;
  padding: 16px;
  display: flex;
  margin-bottom: 5%;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 400px; /* Adjust this based on your design */
}

.card-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.client-info {
  display: flex;
  flex-direction: column;
}

.client-info h3 {
  font-size: 18px;
  margin: 0;
  color: #333;
}

.client-count {
  font-size: 36px;
  margin: 0;
  color: #000;
}

.client-image img {
  max-width: 80px; /* Adjust this based on your design */
}

/* //categery image button // */
.cardcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;

}

.cardimage {
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
}

.cardtext {
  color: #4d5c7c;
  font-size: 18px;
  margin-bottom: 20px;
}

.cardbutton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  background-color: #0072B9;
  color: #fff;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.cardbutton:hover {
  background-color: #0072B9;
}

.cardicon {
  margin-right: 8px;
  font-size: 20px;
  font-weight: bold;
}
.mblpara{
  font-size: 15px;
  margin: 3px;
}
.mblcashin {
  font-size: 16px !important;
  line-height: 22px !important;
  color: #006820 !important;
  margin: 3px;
}
.cashout {
  font-size: 14px !important;
  line-height: 22px !important;
  color: #900a0a !important;
  margin: 3px;
}

.mblcalicon{
  display: flex;
  align-items: center;
  gap: 10px;
}
.page-item a {
  color: #bdbdbd ;
}
.page-item a:hover{
  color: #bdbdbd !important ;
}
.prev-next a{
  color: #bdbdbd ;

}
.page-item:hover{
  color: #bdbdbd !important ;

}
/* FilterComponent.css */

.containerfilter {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0px 0 15px;
}

.dropdown {
  padding: 8px;
  font-size: 14px;
}

.searchBox {
  padding: 8px;
  font-size: 14px;
  flex: 1;
}

.searchButton {
  padding: 8px 16px;
  font-size: 14px;
  background-color: #7587f7;
  color: #FFF;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.searchButton:hover {
  /* background-color: #0056b3; */
  background-color: #0056b3;
}
.searchButton {
  background-color: #7587f7; /* Sky blue background */
  border: none;
  cursor: pointer;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchButton:hover {
  background-color: #aacaec; /* Darker blue on hover */
}

.searchIcon {
  filter: brightness(0) invert(1); /* Makes the icon white */
  width: 22px;
  height: auto; /* Maintains aspect ratio */
}

.searchButton:hover .searchIcon {
  filter: brightness(0.7); /* Slightly dims icon on hover */
}
.searchButton {
  background-color: #7587f7; /* Sky blue background */
  border: none;
  cursor: pointer;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchButton:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.searchIcon {
  filter: brightness(0) invert(1); /* Makes the icon white */
  width: 22px;
  height: auto; /* Maintains aspect ratio */
}

.searchButton:hover .searchIcon {
  filter: brightness(100); /* Slightly dims icon on hover */
}
