/* Sidebar.css */


.sidebar {
  width: 250px;
  /* Width when open */
  min-width: 50px;
  /* Minimum width to accommodate icons when closed */
  height: 100vh;
  background-color: white;
  color: white;
  transition: width 0.3s ease;
  overflow-x: unset;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.sidebar.open {
  width: 250px;
}

.sidebar.closed {
  width: 90px;
}

.sidebar-toggle {
  position: absolute;
  top: 50px;
  right: -22px;
  display: flex;
  align-items: center;
  padding: 10px;
  z-index: 1000;
}

.sidebar-toggle img,
.sidebar-toggle svg {
  width: 24px;
  height: 24px;
  background: black;
  cursor: pointer;
  color: #fff;
  border: 0.5px solid #B86E9FCC;
  border-radius: 50%;
}

.sidebar-menu {
  padding-bottom: 40px;
  position: relative;
  list-style-type: none;
}

.sidebar-menu li span {
  font-family: sans-serif;

}

.sidebar-title {
  margin: 15px 29px 0px;
  font-family: sans-serif !important;
  font-size: 11px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4000000059604645px;
  text-align: center;
  color: #FFFFFF;
}

.menu-button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px;
  border: none;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  border-radius: 7px;
  padding-left: 4px;
  text-decoration: none;
}

.menu-button img,
.menu-button svg {
  margin-left: 5px;
}

.arrow-icon {
  width: 20px;
}

.sidebar-header {
  display: flex;
  justify-content: center;
  position: relative;
}

.header-content {
  display: flex;
  align-items: center;
}

.sidebar-heading {
  display: grid;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 12px;
}

.right-arrow {
  position: absolute;
  right: 25px;
  height: 30px;
  width: 30px;
}


.streetlights {
  margin-top: -13px;
  width: 54px;
}

.profile-logo {
  width: 100px;
}

.profile-name {
  margin-top: 40px;
  font-family: sans-serif !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  text-transform: capitalize;
}

.horizontal-line {
  margin: 2% 17% 5%;
  color: #A33B3B;
  opacity: 30%;
}

.menu-icon-dash {
  width: 24px;
  height: 24px;
  margin-left: 15px !important;
}

.menu-icon-file {
  width: 28px;
  height: 28px;
  margin-left: 14px !important;
}

.menu-icon-logout-active {
  width: 28px;
  height: 28px;
  margin-left: 14px !important;
}

.menu-icon-file-active {
  width: 23px;
  height: 23px;
  margin-left: 14px !important;
}





.report-menu-open {
  display: inline-block;
  position: absolute;
  left: 50px;
  top: 200px;
  text-align: left;
  background: #0F090C;
  border: 0.5px solid #B86E9FCC;
  width: 190%;
}

.report-menu-open li {
  list-style: none;
  color: #fff;
  margin-left: 0px !important;
}

.report-menu-open li a {
  display: block;
  padding: 8px 16px;
  color: #fff;
  text-decoration: none;
}

@media only screen and (min-width: 320px) and (max-width: 1024px) {
  .sidebar {
    display: none;
  }
}

@media only screen and (min-width: 320px) and (max-width: 1024px) {
  .mobile_nav_bar {
    display: block !important;
  }

  .main-content {
    padding: 0px !important;
  }

  .chart-container {
    margin-left: -24%;
  }

  .recharts-responsive-container {
    width: 128% !important;
  }

  .recharts-wrapper {
    position: relative;
    cursor: default;
    width: 100%;
    height: 100%;
    max-height: 189px;
    max-width: 420px !important;
  }

  .category-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
  }

}

.mobile_nav_bar {
  display: none;
}

.sibebarcontent {
  height: 50%;
  overflow: auto;
}

.sibebarcontent::-webkit-scrollbar {
  display: none;
}


.sidebar-menuone {
  padding: 35px 15px 10px 15px;
}

.sidebar-menuone li {
  padding-bottom: 15px;
}

.sidebar-menuone li span {
  margin-left: 10px;
}

.menu-button {
  font-size: 15px;
  display: flex;
  align-items: center;
  width: 100%;
  /* Set width to 100% */
  padding: 10px;
  border: none;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  border-radius: 7px;
  padding-left: 3px;
  text-decoration: none;
}

.logout-button {
  display: flex;
  gap: 15px;
  align-items: baseline;
  background-color: #f5f5f5;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  color: #333;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  /* Full width */
}

.logout-button:hover {
  background-color: #e0e0e0;
}

.logout-icon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.webview {
  display: block;
}

@media (min-width:320px) and (max-width:1024px) {
  .webview {
    display: none !important;
  }

  .mblview {
    display: block !important;
  }

  .mblcontent {
    margin-left: 4%;
    margin-right: 4%;
  }

  p {
    margin: 3px !important;
  }

  .cash-in-card {
    background: #EFFCFAFF;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 4% 2%;
  }

  .cash-out-card {
    background: #FDF1F5FF;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 4% 2%;
  }

  .category-title {
    margin: 2%;
  }

  .table-container {
    margin: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow-x: scroll !important;
  }

  .pagination-container {
    display: flow !important;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    margin: 0%;
  }

  .pagination-info {
    font-size: 16px;
    color: #666;
    margin-bottom: 2%;
  }

  .mblcol {
    display: flex;
    justify-content: space-around;
    margin-bottom: 3%;
    align-items: center;
  }

  .category-header {
    display: block !important;
    justify-content: space-between;
    align-items: center;
    background: #ffffff !important;
    border-bottom: 1px solid #e0e0e0;
  }

  .page-item,
  .prev-next {
    margin: 0 0px;
    cursor: pointer;
    font-size: 16px;
    /* border: 1px solid #b9c7b7; */
    padding: 1px 12px;
    border-radius: 5px;
    height: 32px;
    transition: background-color 0.3s, color 0.3s;
  }

  .pb {
    padding-bottom: 2% !important;
  }

  .mblsitecol {
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
    gap: 10%;
    margin: 0% 3%;
  }

  h3 {
    font-family: sans-serif !important;
    font-size: 26px !important;
    line-height: 48px !important;
    font-weight: 700 !important;
    color: #171A1FFF;
  }

  .search-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #dee9f6;
    border-radius: 12px;
    font-family: sans-serif !important;
    padding: 9px;
    width: 90% !important;
    margin: 3% 0% !important;
  }

  .dateexport {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    padding-bottom: unset !important;
  }
  .dropdown-menu-end{
    position: absolute;
    inset: 0px 0px auto auto ;
    --transform: translate3d(-5.66667px, 53px, 0px) !important;
  }
}

@media (min-width:1024px) and (max-width:2500px) {
  .webview {
    display: block !important;
  }

  .mblview {
    display: none !important;
  }

  .cash-amount {
    font-size: 30px;
    font-weight: bold;
    color: #333;
    margin-left: 5%;
    margin-top: -2%;
    margin-right: 5%;
  }
}

html {
  overflow: hidden;
}

.dateexport {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  padding-bottom: 1rem;
}
.headbtn{
  display: flex;
  justify-content: right;
  gap: 15px;
  padding-bottom: 1rem;
}

.nodata {
  text-align: center;
}

.menu-button.active {
  border-radius: 12px;
  width: 90%;
  transition: width 0.3s ease;
  background: #f2f2f2;
  padding: 5% 0%;
}

.menu-button.mob-nav.active {
  font-size: 15px;
  border-radius: 12px;
  width: 90%;
  transition: width 0.3s ease;
  background: #f2f2f2;
  padding: 5% 5%;
  color: #000000 !important;
}

.mob-nav {
  color: gray;
}
.dropdown-menu-end{

}
#webdropdown{
  position: absolute;
  inset: 0px 0px auto auto ;
  transform: translate3d(-66.6px, 23.8px, 0px) !important;
}

.mbldashboard{
  position: sticky;
  top: 0;
}